import React from "react";
import "../App.css";

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = { units: 0, subtotal: 0 };
    this.addOne = this.addOne.bind(this);
    this.removeOne = this.removeOne.bind(this);
  }

  addOne() {
    this.setState((state) => ({
      units: state.units + 1,
    }));

    this.setState((state) => ({
      subtotal: this.props.price * state.units,
    }));
    this.props.totalAdd(this.props.price);
  }

  removeOne() {
    if (this.state.units > 0) {
      this.setState((state) => ({
        units: state.units - 1,
      }));

      this.setState((state) => ({
        subtotal: this.props.price * state.units,
      }));
      this.props.totalSub(this.props.price);
    }
  }
  
  render() {
    return this.itemBody();
  }
  
  itemBody() {
    return (
      <tr>
        <td width="300" align="justify">
          {this.props.description}
        </td>
        <td width="25px">{this.state.units} </td>
        <td width="150px">${this.props.price}</td>
        <button width="50px" className="App-button" onClick={this.removeOne}>
          -
        </button>
        <button className="App-button" onClick={this.addOne}>
          +
        </button>

        <td>${this.state.subtotal}</td>
      </tr>
    );
  }
}

export default Item;
