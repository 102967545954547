import React from "react";
import "./App.css";
import ItemList from "./Containers/ItemList";
import logo3 from "./logo3.jpg";

function App() {
  return (
    <div className='App'>
      <header className='App-header'>
        <img src={logo3} alt='logo' width='300' height='200' />
        <ItemList />
      </header>
    </div>
  );
}

export default App;
