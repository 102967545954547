import React from "react";
import Item from "../Components/Item";
import data from "../data/generalWineList.json";

const redWines = data.red;
const whiteWines = data.white;

//===============sandbox========================================
/*const completeJsonList = Object.keys(data).map((wine, key) => {
  console.log("key: ", key);
  console.log("** wine.item: ", data.item);
});
*/
//========================================================

class ItemList extends React.Component {
  constructor() {
    super();
    this.state = { total: 0 };
    this.handleAddTotal = this.handleAddTotal.bind(this);
    this.handleSubTotal = this.handleSubTotal.bind(this);
  }

  handleAddTotal(price) {
    this.setState((state) => ({
      total: state.total + price,
    }));
  }
  handleSubTotal(price) {
    this.setState((state) => ({
      total: state.total - price,
    }));
  }
  render() {
    return (
      <div>
        <label for="Choose Wines">Choose an Item: </label>

        <select name="Items" id="Items">
          {redWines.map((wine) => {
            return <option value="1">{wine.item}</option>;
          })}
          {whiteWines.map((wine) => {
            return <option value="1">{wine.item}</option>;
          })}
        </select>
        <br />
        <br />
        <table striped bordered hover>
          <thead>
            <tr>
              <th align="justify">Item Description(s)</th>
              <th>Units</th>
              <th>Price</th>
              <th>More/Less</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            {redWines.map((wine, i) => {
              return (
                <Item
                  description={wine.item}
                  price={wine.price}
                  totalAdd={this.handleAddTotal}
                  totalSub={this.handleSubTotal}
                />
              );
            })}
            {whiteWines.map((wine, i) => {
              return (
                <Item
                  description={wine.item}
                  price={wine.price}
                  totalAdd={this.handleAddTotal}
                  totalSub={this.handleSubTotal}
                />
              );
            })}
          </tbody>
        </table>
        <p align="right">Total: ${this.state.total}</p>
      </div>
    );
  }
}

export default ItemList;
